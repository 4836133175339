// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&family=Roboto:wght@300;400;500;700&display=swap');


$enable-rounded: false; 
$enable-responsive-font-sizes: true;


/*colors*/
$themePrimary: #000000; 
// $themeSecondary: #AF0061;
$themeSecondary: #B90066;
$themeLight: #F1F1F1;
$themeWhite: #FFFFFF;
$themeGray: #707070;
// $themeGray: #dadfe1;
$themeDark: #0D0D0D;
$themeInfo: #5489a3;
$themeBlue: #004a98;
$theme-colors: (
    "primary": $themePrimary,
    "secondary": $themeSecondary, 
    "gray": $themeGray,
    "info": $themeInfo,
    "light": rgba($themeLight, 0.95),
    "white": $themeWhite,
    "dark": $themeDark,
    "blue": $themeBlue
);

/*Typography*/
// $font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-sans-serif: 'Montserrat', sans-serif;
// $font-family-sans: 'Roboto',serif;
// $font-family-base: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;


$body-color: $themePrimary;

$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;    

// $headings-font-weight: $font-weight-light;
// $headings-font-family: $font-family-sans-serif;
$font-size-base: 1rem * 0.875;
$font-weight-base: $font-weight-normal;
$font-color-base: $themePrimary;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.7143; 
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.14286;
$h6-font-size: $font-size-base * 1;
$small-font-size: $font-size-base * 0.857;
// $lead-font-size: $font-size-base * 1.388;
// $display1-size: $font-size-base * 5;

$display-font-sizes: (
  1: $font-size-base * 5.7,
//   2: 4.5rem,
//   3: 4rem,
//   4: 3.5rem,
//   5: 3rem,
//   6: 2.5rem
);

// $display4-size: $font-size-base * 2.03;

// $lead-font-weight: $font-weight-light;
// $display1-weight: $font-weight-medium;
// $display4-weight: $font-weight-normal;
// /*Links and buttons*/
// $link-color: $themeSecondary;
// $btn-padding-y: 0.6rem;
// $btn-padding-x: 4.2rem;
// $btn-border-width: 2px;
// $btn-font-size: 1rem;
// /*Navbar*/
// $navbar-light-color: $themePrimary;
// $navbar-light-hover-color: $themePrimary;
// $navbar-light-active-color: $themePrimary;
// $navbar-padding-y: 1rem;
// $navbar-nav-link-padding-x: 0;
// $navbar-brand-padding-y: 0.4rem;
// $dropdown-min-width: 4rem;
/*hr*/
$hr-color: $themeGray;
$hr-opacity: 0.6;
$hr-height: 1.28px;
/*$hr-margin-y: 1.875rem;*/
// /*inputs*/
// $input-border-color: $themePrimary;
// $input-border-width: 2px;
// $input-padding-y: 1.1rem;
// $input-padding-x: 1.375rem;
// $input-disabled-bg: #ffffff;
// $custom-select-border-color: $themePrimary;
// $custom-select-border-width: 2px;
// $custom-select-indicator-color: $themePrimary; 


/*Typography*/
h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6, 
.display-1, .display-2, .display-3, .display-4  {
    font-family: $font-family-sans-serif;
    font-weight: 600 !important;
}

$border-radius-lg: 0.875rem;
$border-widths: (
    // 1: 1px,
    2: 1.6px,
    3: 3px,
    // 4: 4px,
    // 5: 5px
);


/* Print */
// @media print {
    // $grid-breakpoints: (
    // xs: 0,
    // sm: 576px,
    // md: 768px,
    // lg: 992px,
    // xl: 1200px,
    // xxl: 1400px
    // );
// }




/* =================== import bootstrap to set changes ==================== */
@import "../node_modules/bootstrap/scss/bootstrap";



.col-20-percent {
    flex: 0 0 auto;
    width: 20% !important;
}
.col-24-percent {
    flex: 0 0 auto;
    width: 24% !important;
}
.col-30-percent {
    flex: 0 0 auto;
    width: 30% !important;
}

.font-size-80 {
    font-size: 3.8rem !important
}

@include media-breakpoint-down(md) {
    .col-24-percent {
        width: 49% !important;
    }
}
@include media-breakpoint-down(sm) {
    .col-20-percent {
        width: 50% !important;
    }
    .col-24-percent {
        width: 100% !important;
    }
    .col-30-percent {
        width: 100% !important;
    }
}
.fw-900 {
    font-weight: 900 !important;
}

.font-family-base {
    font-family: $font-family-base !important;
}
.font-size-base {
    font-size: $font-size-base !important;
}